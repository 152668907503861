import { memo } from 'react';

import Map from '@rio-cloud/rio-uikit/Map';
import Marker from '@rio-cloud/rio-uikit/Marker';
import SingleMapMarker from '@rio-cloud/rio-uikit/SingleMapMarker';
import { connect } from 'react-redux';
import { getVehicles } from '../../data/redux/data.redux';
import {
    getLatestAssetId,
    getLatestBearing,
    getLatestLatitude,
    getLatestLongitude,
} from '../redux/detailsSidebar.redux';
import { FormattedMessage } from 'react-intl';
import { buildUrl } from './liveMonitorUrlBuilderService';
import { State } from '../../../reducers';
import { rioglyphIconTypeByAssetType } from './vehicleIconService';
import useDarkMode from '@rio-cloud/rio-uikit/useDarkMode';
import { Position } from '@rio-cloud/rio-uikit/components/map/utils/mapTypes';
import { config } from '../../../config';

interface MapWrapperProps {
    lat: number | undefined;
    lng: number | undefined;
    bearing?: number;
    name?: string;
    type?: string;
    liveMonitorUrl?: string;
}

const createVehicleMarker = (
    center: Position,
    name: string | undefined,
    bearing: number | undefined,
    type: string | undefined
): JSX.Element => {
    const markerIcon = (
        <SingleMapMarker
            active={false}
            bearing={bearing}
            name={name}
            iconNames={[rioglyphIconTypeByAssetType(type)]}
            moving={bearing !== undefined}
        />
    );

    return <Marker position={center} icon={markerIcon} />;
};

// eslint-disable-next-line react/display-name
const MapWrapper = memo((props: MapWrapperProps) => {
    const { lat = 48.179, lng = 11.595, bearing, name, type, liveMonitorUrl } = props;
    const center = { lat, lng } as Position;
    const isDarkModeEnabled = useDarkMode();
    const hereCredentials = { apikey: config.hereApiKey };

    return (
        <>
            <div className={'height-200 margin-top-3 margin-bottom-5 border rounded'}>
                <Map
                    credentials={hereCredentials}
                    hideMapSettings={true}
                    center={center}
                    zoom={10}
                    mapType={isDarkModeEnabled ? 'NIGHT' : 'DEFAULT'}
                >
                    {name && createVehicleMarker(center, name, bearing, type)}
                </Map>
            </div>
            <a href={liveMonitorUrl} target={'_blank'} rel="noopener noreferrer">
                <FormattedMessage id={'intl-msg:timed.sidebar.goToFleetmonitor'} />
            </a>
        </>
    );
});

const getVehicleName = (state: State): string | undefined => {
    const selectedAssetItem = getVehicles(state).find((vehicle) => vehicle.id === getLatestAssetId(state));
    return selectedAssetItem?.name;
};

const getVehicleType = (state: State): string | undefined => {
    const selectedAssetItem = getVehicles(state).find((vehicle) => vehicle.id === getLatestAssetId(state));
    return selectedAssetItem?.type;
};

const mapStateToProps = (state: State): MapWrapperProps => {
    return {
        lat: getLatestLatitude(state),
        lng: getLatestLongitude(state),
        bearing: getLatestBearing(state),
        name: getVehicleName(state),
        type: getVehicleType(state),
        liveMonitorUrl: buildUrl(state),
    };
};

export const MapWrapperContainer = connect(mapStateToProps)(MapWrapper);
