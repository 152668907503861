import { Moment } from 'moment';
import { StatusDuration } from '../tables/cells/types';

export interface DetailsSidebarDisplayItem {
    id: string;
    vehicleName?: string;
    assetId: string;
    driverName: string;
    driverId: string;
    timeStamp: Moment;
    multiManning?: boolean;
    remainingRestingExceptions?: number;
    nextDrivingDuration?: number;
    nextDrivingTimeLeft?: number;
    nextRestingDuration?: number;
    nextRestingTimeLeft?: number;
    statusDuration?: StatusDuration;
    minimumDailyRestMinimum?: number;
    shiftTimes?: ShiftTimes;
}

export interface ShiftTimes {
    shiftStart: Moment;
    shiftEnd: Moment;
    shiftTime: number;
    shiftMax: number;
    shiftExceeded: boolean;
    timeLeft: number;
    dailyRestWarning: boolean;
}

export interface DetailSidebarStateProps {
    isRealtimeSidebarOpen: boolean;
    driverState: DetailsSidebarDisplayItem | undefined;
    receivedTimestamp: Moment | undefined;
    displayLocale: string;
}

export interface DetailSidebarDispatchProps {
    closeSidebar: () => void;
}

export enum LiveMonitorUrlParameterKeys {
    SELECTED_ASSET_IDS = 'assetIds',
}

export interface NextActivityPanelProps {
    nextActivityTimeLeft?: number;
    nextActivityDuration?: number;
    isDataUpToDate?: boolean;
}
