import { ConfigState } from './types';

const asBool = (value: string | undefined): boolean => value === 'true';

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        TAGS_SERVICE: import.meta.env.VITE_TAGS_SERVICE,
        DRIVER_SERVICE: import.meta.env.VITE_DRIVER_SERVICE,
        ASSET_ADMINISTRATION: import.meta.env.VITE_ASSET_ADMINISTRATION,
        ASSET_LIVE_STATE: import.meta.env.VITE_ASSET_LIVE_STATE_API,
        PRODUCT_ACTIVATIONS_API: import.meta.env.VITE_PRODUCT_ACTIVATIONS_API,
        DRIVINGTIME_CONTEXT: import.meta.env.VITE_DRIVINGTIME_CONTEXT,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: 'b1880eae-c69d-4f88-97a2-effb2e59968e',
        oauthScope: ['openid', 'profile', 'email'],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: 'https://6f56226304634e23b0a2d260c288d78b@o117480.ingest.sentry.io/5208275"',
    version: import.meta.env.VITE_VERSION,
    configCat: {
        apiKey: 'nUDbCOqlGEaK8AGkogO5hw/MRchj5S9SECj7QITYlwYOg',
    },
    hereBaseUrl: 'https://js.api.here.com/v3/3.1/',
    hereApiKey: import.meta.env.VITE_HERE_API_KEY,
};
