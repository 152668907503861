/* eslint-disable no-case-declarations */

import { AssetBookings, AssetItem, DataState, Driver, Feature, Tag } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { State } from '../../../reducers';
import { TreeGroup } from '@rio-cloud/rio-uikit/Tree';

const name = 'data';

const initialState: DataState = {
    vehicleGroups: [],
    vehicles: [],
    driverGroups: [],
    drivers: [],
    tags: [],
    assetBookings: undefined,
    features: [],
};

const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers: {
        fetchedAssets: (state: DataState, action: PayloadAction<AssetItem[]>): void => {
            state.vehicles = action.payload;
        },
        fetchedDrivers: (state: DataState, action: PayloadAction<Driver[]>): void => {
            state.drivers = action.payload;
        },
        fetchedTags: (state: DataState, action: PayloadAction<Tag[]>): void => {
            state.tags = action.payload;
        },
        fetchedVehicleGroups: (state: DataState, action: PayloadAction<TreeGroup[]>): void => {
            state.vehicleGroups = action.payload;
        },
        fetchedDriverGroups: (state: DataState, action: PayloadAction<TreeGroup[]>): void => {
            state.driverGroups = action.payload;
        },
        setAssetBookings: (state: DataState, action: PayloadAction<AssetBookings | undefined>): void => {
            state.assetBookings = action.payload;
        },
        setFeatures: (state: DataState, action: PayloadAction<Feature[]>): void => {
            state.features = action.payload;
        },
    },
});

export const dataActions = actions;
export const dataReducer = reducer;

const getBase = (state: State): DataState => state.data;

export const getVehicles = (state: State): AssetItem[] => get(getBase(state), 'vehicles', []);
export const getVehicleGroups = (state: State): TreeGroup[] => get(getBase(state), 'vehicleGroups', []);
export const getDrivers = (state: State): Driver[] => get(getBase(state), 'drivers', []);
export const getDriverGroups = (state: State): TreeGroup[] => get(getBase(state), 'driverGroups', []);
export const getTags = (state: State): Tag[] => get(getBase(state), 'tags', []);

export const getAssetBookings = (state: State): AssetBookings | undefined => getBase(state).assetBookings;
export const getFeatures = (state: State): Feature[] => get(getBase(state), 'features', []);
