import { connect } from 'react-redux';

import { DetailSidebar } from './DetailSidebar';

import {
    getReceivedTimestampOfDrivingTimes,
    getSelectedDrivingTimesRowId,
    realtimeActions,
} from '../realtime/redux/realtime.redux';
import { Dispatch } from 'redux';
import { getLocale } from '../../configuration';
import { getDetailsSidebarDisplayItems } from './redux/detailsSidebar.redux';
import { DetailSidebarDispatchProps, DetailSidebarStateProps, DetailsSidebarDisplayItem } from './types';
import { State } from '../../reducers';

export const mapDispatchToProps = (dispatch: Dispatch): DetailSidebarDispatchProps => ({
    closeSidebar: (): void => {
        dispatch(realtimeActions.selectDrivingTimesTableRow(undefined));
    },
});

const getDriverStateForDetailsSidebar = (state: State): DetailsSidebarDisplayItem | undefined => {
    const activeRowId = getSelectedDrivingTimesRowId(state);
    return getDetailsSidebarDisplayItems(state).find((item) => item.id === activeRowId);
};

export const mapStateToProps = (state: State): DetailSidebarStateProps => ({
    isRealtimeSidebarOpen: getSelectedDrivingTimesRowId(state) !== undefined,
    driverState: getDriverStateForDetailsSidebar(state),
    receivedTimestamp: getReceivedTimestampOfDrivingTimes(state),
    displayLocale: getLocale(state),
});

export const DetailSidebarContainer = connect(mapStateToProps, mapDispatchToProps)(DetailSidebar);
