import './polyfills';
import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router';

import { handleLoginRedirect, main, store } from './configuration';

import { config } from './config';
import { ErrorBoundary } from './features/app/components/ErrorBoundary';
import { AppContainer } from './features/app/AppContainer';
import { WidgetContainer } from './features/widget/Widget.container';
import './configuration/setup/datadog';
import { ConfigCatProvider, createConsoleLogger, DataGovernance, LogLevel, PollingMode } from 'configcat-react';
import { createRoot } from 'react-dom/client';

const renderApplication = (): void => {
    console.debug('version', config.version);
    createRoot(document.getElementById('root') as HTMLElement).render(
        <ErrorBoundary>
            <ConfigCatProvider
                sdkKey={config.configCat.apiKey}
                pollingMode={PollingMode.AutoPoll}
                options={{
                    pollIntervalSeconds: 2 * 86400, // 2 days
                    dataGovernance: DataGovernance.EuOnly,
                    logger: createConsoleLogger(LogLevel.Off),
                }}
            >
                <Provider store={store}>
                    <HashRouter>
                        <Routes>
                            <Route path="/widget" element={<WidgetContainer />} />
                            <Route path="/*" element={<AppContainer />} />
                        </Routes>
                    </HashRouter>
                </Provider>
            </ConfigCatProvider>
        </ErrorBoundary>
    );
};
if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
