import { TreeGroup, TreeItem } from '@rio-cloud/rio-uikit/Tree';

export interface DataState {
    vehicleGroups: TreeGroup[];
    vehicles: AssetItem[];
    driverGroups: TreeGroup[];
    drivers: Driver[];
    tags: Tag[];
    assetBookings?: AssetBookings;
    features: Feature[];
}

export enum DriverStatus {
    ACTIVE = 'active',
    ARCHIVED = 'archived',
}

export interface Driver {
    id: string;
    displayName: string;
    status: DriverStatus;
    tagIds: string[];
}

export interface Tag {
    id: string;
    type: string;
    name: string;
    accountId: string;
}

export interface Asset {
    id: string;
    accountId: string;
    name: string;
    status: string;
    type: string;
    tagIds: string[];
}

export interface AssetItem extends TreeItem {
    id: string;
    type: string;
    name: string;
    groupIds: string[];
}

export enum Product {
    TIMED = 'TIMED',
    COMPLIANT = 'COMPLIANT',
}

export enum ProductStatus {
    ACTIVE = 'Active',
    PENDING = 'Pending',
}

export enum Feature {
    WORKING_TIMES = 'WORKING_TIMES',
}

export interface AssetProduct {
    product: Product;
    statusChangeDate: string;
    status: ProductStatus;
}

export interface AssetBookings {
    [key: string]: AssetProduct[];
}

export interface ProductActivations {
    assetBookings: AssetBookings;
    features: Feature[];
}
